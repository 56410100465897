import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>BAD BREATH</h2>
      <h3>What is it?</h3>
      <p>Bad breath is usually caused by bacteria in the mouth and is not usually a sign of general ill health.</p>
      <p>Gum disease can cause bad breath. But you could still have bad breath with good oral hygiene, and healthy teeth and gums. The smell then usually comes from the far back of the tongue. It gets stronger when you talk, as your mouth gets drier.</p>
      <p>Most adults occasionally suffer from bad breath; perhaps a quarter have it regularly.  If you are worried about bad breath, please talk to your dentist. Your dentist will:</p>
      <ul>
        <li>work out whether  you really have a bad-breath problem</li>
        <li>find out what sort of problem it is and help you deal with it.</li>
      </ul>
      <h3>What can my dentist do?</h3>
      <p>Make sure, before you see your dentist, that you have not done anything to hide the normal smell of your breath. Do not smoke, chew gum or use a mouth rinse, and avoid any sort of perfume; do not clean your teeth immediately before your appointment.</p>
      <p>Your dentist might use a plastic spoon to gently scrape the back of your tongue and test the smell. There are also instruments that measure sulphur compounds in the mouth or swelling around the gums.</p>
      <p>Good oral hygiene will usually be the answer  to a bad-breath problem. A scale and polish from your dentist or hygienist makes it easier for you to keep your mouth clean at home.</p>
      <h3>What can I do myself?</h3>
      <ul>
        <li>Brush your teeth thoroughly twice a day with a fluoride toothpaste.</li>
        <li>Use floss or other oral hygiene  aids if your dentist or hygienist recommends them. You can check bad breath by smelling the floss after you have used it. If there is a smell from a particular part of the mouth, clean that area with special care.</li>
        <li>Gently brush your tongue if it is heavily coated. Special plastic tongue scrapers can be bought from chemists.</li>
      </ul>

      <p>A mouth will smell less if it is moist.  Chewing sugar-free gum can help with bad breath by increasing the flow of saliva.  You migh  have a dry mouth  because you breathe with your mouth open or because you are taking  certain  medicines.</p>
      <p>If you smoke, try to give up. As well as making your breath smell better, giving up smoking will keep your mouth and gums healthy, and benefit your general health.</p>
    </LightboxLayout>
  )
}

export default Page